import { difference } from "lodash";
import React from "react";

type Action =
  | {
      type: "START_VALIDATING";
      payload: string[];
    }
  | {
      type: "FINISH_VALIDATING";
      payload: string[];
    };

function reducer(state: string[], action: Action) {
  switch (action.type) {
    case "START_VALIDATING":
      return [...state, ...action.payload];
    case "FINISH_VALIDATING":
      return difference(state, action.payload);
    default:
      return state;
  }
}

const initialState: {
  validating: string[];
  dispatch: React.Dispatch<Action>;
} = {
  validating: [],
  dispatch: () => null,
};

const ValidationContext = React.createContext(initialState);

export function ValidationContextProvider({ children }) {
  const [state, dispatch] = React.useReducer(reducer, []);

  return (
    <ValidationContext.Provider value={{ validating: state, dispatch }}>
      {children}
    </ValidationContext.Provider>
  );
}

export function useValidationContext() {
  return React.useContext(ValidationContext);
}
