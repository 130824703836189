import { FieldAttributes, useField, Field, useFormikContext } from "formik";
import { Form, Input, Checkbox } from "antd";
import React from "react";
import { useValidationContext } from "context/status-context";
import { flatMap } from "lodash";

function getInput(type) {
  switch (type) {
    case "password":
      return Input.Password;
    default:
      return Input;
  }
}

export default function FormItem({
  name,
  label,
  note,
  className,
  notApplicable,
  disabled = false,
  extra,
  ...props
}: {
  name: string;
  label?: string | JSX.Element;
  className?: string;
} & FieldAttributes<any>) {
  const { validating } = useValidationContext();
  const [field, meta] = useField(name);
  let value = field.value;
  let na = false;
  if (notApplicable && value === "N/A") {
    value = "";
    na = true;
  }
  let error = meta.touched && meta.error;
  const loading = validating.indexOf(field.name?.split(".")[0]) > -1;
  if (Array.isArray(error)) {
    error = flatMap(error, (x) => Object.values(x)) as any;
  }
  return (
    <>
      <Form.Item
        htmlFor={name}
        label={label}
        colon={false}
        labelCol={{ span: 24 }}
        hasFeedback
        validateStatus={loading ? "validating" : error && "error"}
        help={
          <div className="flex justify-between">
            <div>{!loading && error}</div>
            <div className="text-gray">{extra}</div>
          </div>
        }
        className={className}
      >
        <Field
          id={name}
          className="w-full"
          name={name}
          as={props.children ? null : getInput(props.type)}
          value={value}
          checked={value}
          disabled={na || disabled}
          {...props}
        />
      </Form.Item>
      {notApplicable && (
        <NotApplicable
          name={name}
          checked={na}
          disabled={disabled || props?.readOnly}
        />
      )}
      {note}
    </>
  );
}

function NotApplicable({ checked, disabled, name }) {
  const [field] = useField(name?.split(".")[0]);
  const { setFieldValue } = useFormikContext();
  return (
    <div className="mb-4">
      <label>
        <Checkbox
          disabled={disabled}
          onChange={(e) => {
            setFieldValue(
              field.name,
              e.target.checked
                ? { ...field.value, value: "N/A", values: "N/A", comment: "" }
                : { ...field.value, value: "", values: undefined, comment: "" }
            );
          }}
          checked={checked}
        />{" "}
        N/A
      </label>
    </div>
  );
}
